<template>
  <div class="login-wrapper">
    <div v-if="mode == 'gestionale'" class="image"></div>
    <div v-else-if="mode == 'automotive'" class="image-automotive"></div>
    <div v-else class="image-crm"></div>
    <div class="form-signin">
      <form @submit.prevent="login">
        <img class="logo mb-3" :src="company.logo" />
        <h1 class="mb-5">{{ company.nome }}</h1>
        <div class="logo-image cursor-pointer mb-6">
          <img
            class="logo-menu"
            style="width: 172px"
            src="../../assets/images/logo.png"
          />
        </div>

        <InputText
          id="username"
          class="w-full mb-5"
          type="text"
          v-model="username"
          placeholder="Nome Utente"
        />

        <Password
          inputStyle="width: 100%"
          style="width: 100%"
          id="password"
          class="w-full mb-5"
          v-model="password"
          :feedback="false"
          placeholder="Password"
          toggleMask
        />

        <Button :loading="loading" label="Entra" class="w-100" type="submit" />
      </form>

      <div class="w-full flex justify-content-center align-items-center my-4">
        <span>Hai dimenticato la password? </span>
        <Button
          text
          label="Clicca qui"
          class="p-button-link ml-2"
          @click="forgotPassword"
        />
      </div>

      <div v-if="accessoNonAbilitatoVisible">
        <Message severity="error">Accesso non abilitato</Message>
      </div>

      <div v-if="authStatus == 'error'">
        <Message severity="error"> Problema con l'autenticazione </Message>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "@/axiosServices/AxiosService";
import { AUTH_REQUEST, AUTH_LOGOUT } from "../../store/actions/auth";
import { USER_REQUEST, USER_SET_LEVEL } from "@/store/actions/user";
import store from "../../store";
import { mapGetters, mapActions } from "vuex";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      mode: "",
      accessoNonAbilitatoVisible: false,
      loading: false,
      level_options: [],
      level_selected: 0,
    };
  },
  created() {
    const CURRENT_URL = window.location.href;
    if (CURRENT_URL.includes("prestitosi-gest")) {
      this.mode = "gestionale";
    } else if (CURRENT_URL.includes("prestitosi-automotive")) {
      this.mode = "automotive";
    } else {
      this.mode = "crm";
    }
  },
  methods: {
    ...mapActions("USER_SET_LEVEL"),
    forgotPassword() {
      this.$router.push("changepasswordrequest");
    },
    setLevel(level) {
      this.$store.dispatch(USER_SET_LEVEL, level);
    },
    setLoginLevel() {
      console.log("setLoginLevel", this.level_selected);
      this.loading = true;
      const service = new AxiosService("SetLoginLevel/" + this.level_selected);
      service
        .create()
        .then((res) => {
          this.setLevel(
            this.level_options.filter((x) => x.Id == this.level_selected)[0]
              .Nome
          );
        })

        .finally(() => {
          this.loading = false;
          this.$router.push("/");
        });
    },
    login: function () {
      this.loading = true;

      this.accessoNonAbilitatoVisible = false;
      const authObject = {
        username: this.username,
        password: this.password,
        portal: this.mode,
      };

      this.$store
        .dispatch(AUTH_REQUEST, authObject)
        .then((res) => {
          this.authStatus = store.getters.authStatus;
          if (res.data.ActionRequired == "ChangePassword") {
            this.$router.push("changepasswordrequest");
          }
          if (!res.data.Success) {
            this.accessoNonAbilitatoVisible = true;
            this.logout();
          } else {
            this.level_options = this.getLivelliLogin;
            this.level_selected = this.level_options[0].Id;
            console.log("login", this.level_options);
            this.setLoginLevel();

            // this.$router.push("setloginlevel");
          }
        })
        .catch(() => {
          this.authStatus = store.getters.authStatus;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    logout: function () {
      const service = new AxiosService("Auth/Logout");
      service.create();
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("login");
      });
    },
  },
  beforeMount() {
    if (store.getters.isAuthenticated) {
      this.$router.push("/");
    }
  },
  computed: {
    ...mapGetters(["company", "getLivelliLogin"]),
  },
};
</script>

<style scoped>
.p-input-icon-right > .p-inputtext {
  width: 100% !important;
}
</style>
