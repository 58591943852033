import { createWebHistory, createRouter } from "vue-router";
import store from "../store";
import AppLayout from "@/views/layout/AppLayout.vue";

const CURRENT_URL = window.location.href;

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/login/Login.vue"),
      meta: {
        title: "DataRete - Accesso",
      },
    },
    {
      path: "/changepasswordrequest",
      name: "ChangePasswordRequest",
      component: () => import("../views/login/ChangePasswordRequest.vue"),
      meta: {
        title: "DataRete - Richiesta Cambio Password",
      },
    },
    {
      path: "/changepasswordperform/:code",
      name: "ChangePasswordPerform",
      component: () => import("@/views/login/ChangePasswordPerform.vue"),
      meta: {
        title: "DataRete - Realizza Cambio Password",
      },
    },
    {
      path: "/setloginlevel",
      name: "SetLoginLevel",
      component: () => import("../views/login/SetLoginLevel.vue"),
      meta: {
        title: "DataRete - Seleaziona il livello di Accesso",
      },
    },
    {
      path: "/logout",
      name: "Logout",
      component: () => import("../views/PerformLogout.vue"),
    },
    {
      path: "/conferma-pagamento",
      name: "ConfermaPagamento",
      component: () => import("../views/ConfermaPagamento.vue"),
    },
    {
      path: "/errore-pagamento",
      name: "ErrorePagamento",
      component: () => import("../views/ErrorePagamento.vue"),
    },
    {
      path: "/link/:externalLink",
      name: "Link",
      component: () => import("../views/Link.vue"),
    },
    {
      path: "/",
      component: AppLayout,
      children: [
        {
          path: "",
          name: "Bacheca",
          component: () => import("@/views/Bacheca.vue"),
        },
        {
          path: "profilo",
          name: "ProfiloUtente",
          component: () => import("@/views/ProfiloUtente.vue"),
        },

        {
          path: "/testtry1",
          name: "test",
          component: () => import("@/views/TestTry.vue"),
        },
        {
          path: "/testtry3",
          name: "test3",
          component: () => import("@/views/Testtry3.vue"),
        },

        {
          path: "gestione",
          name: "gestione",
          children: [
            {
              path: "utenti",
              component: () => import("@/views/gestione/Users.vue"),
            },
            {
              path: "notizie",
              component: () => import("@/views/gestione/Notizie.vue"),
            },
          ],
        },
        {
          path: "plugin",
          name: "plugins",
          children: [
            {
              path: "data-drive",
              name: "DataDrive",
              component: () => import("@/views/plugins/DataDrive.vue"),
            },
          ],
        },
        {
          path: "agenda",
          name: "Agenda",
          children: [
            {
              path: "calendario",
              name: "Calendario",
              component: () => import("@/views/agenda/Calendario.vue"),
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  console.log("Authenticated:" + store.getters.isAuthenticated);

  if (
    to.name !== "Login" &&
    to.name !== "ChangePasswordRequest" &&
    to.name !== "ChangePasswordPerform" &&
    to.name !== "DataRete-PerformVideoCall" &&
    !store.getters.isAuthenticated
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

// const DEFAULT_TITLE = 'DataRete';
// router.afterEach((to) => {
//     Vue.nextTick(() => {
//         document.title = to.meta.title || DEFAULT_TITLE;
//     });
// });

export default router;
